@import '../lib/settings.less';
@import '../classes/classes.less';

.eO5oqkbg21Im{

    display: block;
    position: relative;
    z-index: 1;

    .wrapper-content{

        display: block;
        height: 100%;
        padding: 0;

        .wrapper-nav{
    
            .displayFlex(row);
            .justifyContent(space-between);
    
            padding: 0;
            height: calc(100% - (@p_main * 2));
            width: 100%;

            .transform2d(@translateY: -50%);

            p{
                .f_1stFont;
                
                display: inline-block;
                color: @c_5th;
                font-size: 18px;
            }
    
            .box-left{
    
                .displayFlex(row);
                .justifyContent(flex-start);
    
                position: relative;
                width: 100%;
                height: 100%;
        
                .logo{
    
                    display: inline-block;
                    height: 100%;
                    cursor: pointer;
        
                    img{
                        height: 100%;
                        object-fit: contain;
                    }
                }
    
                .title{
    
                    display: inline-block;
                    margin-left: @p_main;
        
                    .transform2d(@translateY: -50%);
                }
            }

            .box-right{
                ul{

                    &.parent{

                        .displayFlex(row);
                        .justifyContent(flex-end);
                        .transform2d(@translateY: -50%);

                        position: relative;
                        z-index: 3;

                        li{

                            margin-left: (@p_main * 1.5);

                            &:hover .subnavi{
                                height: auto;
                                // background-color: yellowgreen;
                            }
                        }
                    }

                    &.subnavi{

                        position: absolute;
                        top: 22px;
                        left: 0;
                        width: 100%;
                        height: 0;
                        overflow: hidden;
                        // background-color: red;

                        &:hover{
                            height: auto;
                            // background-color: yellowgreen;
                        }

                        .standardAnimation(@_time: 150ms);

                        li{

                            position: relative;
                            z-index: 1;
                            padding: @p_main (@p_main * 2);
                            // background-color: red;

                            &:first-child{
                                padding-top: (@p_main * 2);
                            }
                        }
                    }


                    li{

                        a{
                            display: inline-block;
                            color: @c_5th;
                            text-align: right;
                            width: 100%;
                        }
                    }
                }
            }
        }
        @media screen and(max-width: @s_4thMainWrapper){
            .wrapper-nav{
                .box-left{
                    p{
                        display: none;
                        font-size: (@f_mainSize * 0.75);
                        margin: 0;
                        margin-left:(@p_main / 2);
                    }
                }
            }
        }
    }
}